import React from 'react'
import './design.css';
import sec3_image1 from '../../../image/design2.png'
import sec3_image2 from '../../../image/design6.png'
import sec3_image3 from '../../../image/design3.png'
import sec3_image5 from '../../../image/design1.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCameraRetro , faArrowRight} from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";



const design = () => {
  return (
    <div className="design">
      <div className="sec4-text cont-head-text">
        <h1 className='head-text' >Designs</h1>
        <h3>This section is your first destination to discover the best modern and attractive website designs</h3>
      </div>
      <div className="text4 ">
        <div className="shado blur-c"></div>
      <FontAwesomeIcon className=' icon' icon={faCameraRetro} />
      <h1>Galiry</h1>
      </div>
<div className='galiry'>
        <div className="image1">
          <img src={sec3_image2} alt="" />
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem cum magni nostrum consequuntur libero odit temporibus cupiditate eligendi quibusdam animi quam quas, neque sed aliquid blanditiis tempora ab quasi perferendis.</p>
        </div>
        <div className="image2">
          <img src={sec3_image1} alt="" />
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem cum magni nostrum consequuntur libero odit temporibus cupiditate eligendi quibusdam animi quam quas, neque sed aliquid blanditiis tempora ab quasi perferendis.</p>
        </div>
        <div className="image3">
          <img src={sec3_image5} alt="" />
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem cum magni nostrum consequuntur libero odit temporibus cupiditate eligendi quibusdam animi quam quas, neque sed aliquid blanditiis tempora ab quasi perferendis.</p>
        </div>
        <div className="image4">
          <img src={sec3_image3} alt="" />
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem cum magni nostrum consequuntur libero odit temporibus cupiditate eligendi quibusdam animi quam quas, neque sed aliquid blanditiis tempora ab quasi perferendis.</p>
        </div>
</div>
<Link className="btn" to="design">View More Design <FontAwesomeIcon className=' icon-btn' icon={faArrowRight} /></Link>
    </div>
  )
}

export default design
