import React from 'react'

import './tutorial.css';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckDouble ,faArrowRight } from '@fortawesome/free-solid-svg-icons'

import sec5_image1 from '../../../image/toturial1.png'
import sec5_image2 from '../../../image/toturial2.png'
import sec5_image3 from '../../../image/toturial3.jpeg'

const tutorial = () => {
  return (
    <div className="toturial">
      <div className="sec5-text cont-head-text">
        <h1 className='head-text'>Toturial</h1>
        <h3>This section specializes in providing toturial in the field of  <b>Webdevelopment</b></h3>
      </div>
    <div className="image2">
      <img src={sec5_image2} alt="" />
    </div>
    <div className="image3">
      <img src={sec5_image3} alt="" />
    </div>
    <div className="image1">
      <img src={sec5_image1} alt="" />
    </div>

<div className="text1">
<h3> <FontAwesomeIcon className='icon-check' icon={faCheckDouble} /> Comprehensive set of toturial</h3>
<p>and web design. It is an ideal destination for individuals who want to acquire the knowledge and skills needed to create and improve websites on the Internet in advanced and professional ways.</p>
</div>
<div className="text2">
<h3> <FontAwesomeIcon className='icon-check' icon={faCheckDouble} /> Interactive teaching style</h3>
<p>It focuses on the practical participation of students. The site uses high-quality explanatory videos, practical exercises, and practical projects to enable learners to apply what they have learned on the ground. The site also provides means of communication with teachers and other students to exchange experiences and knowledge.</p>
</div>


<Link className="btn" to="toturial">View More Toturials <FontAwesomeIcon className=' icon-btn' icon={faArrowRight} /></Link>
    </div>
  )
}

export default tutorial