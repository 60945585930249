import React from 'react'
import './community.css';
import sec10_image from '../../../image/community.gif'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight} from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";
const community = () => {
  return (
    <div className="community">
      <div className="sec10-text cont-head-text">
        <h1 className='head-text' >Community</h1>
        <h3>Welcome to the world of creativity and communication, where programmers from all over the world gather in one place!</h3>
      </div>
      <div className="sec10-image">
        <img src={sec10_image} alt="" />
      </div>
      <div className="heading">
        <h1>Join us now and become a part of us, where you can participate and meet distinguished programmers and achieve common successes.</h1>
      </div>
      <div className="paragraph">
        <p>It is a section that brings together a passionate community of programmers and programming lovers. Whether you are a beginner looking to gain basic knowledge, or a professional looking to improve and develop, we support you and offer you the perfect environment to network and learn.</p>
        <p>Effective Communication: You can interact with our community through discussion forums and talk about the latest technologies and programming ideas.</p>
        <p>Premium Content: We provide high quality articles and tutorials to help you improve your skills and understand new programming concepts.</p>
      </div>
      <Link className="btn" to="design">Join us Now <FontAwesomeIcon className=' icon-btn' icon={faArrowRight} /></Link>
    </div>
  )
}

export default community