import React from 'react';
import './footer.css' ;
import logo from '../../image/logo.png'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faFacebookF ,faTelegram ,faInstagram , faWhatsapp} from '@fortawesome/free-brands-svg-icons'
const footer = () => {
  return (
    <div className="footer">
      <div className='top'>
        
        <div className="footer-logo">
          <img className='footer-logo_img' src={logo} alt="Logo" />
          <h1>Learn From Scratch</h1>
        </div>
          <div className='footer-links'>
            <Link className='link' to="/" >Home</Link>
            <Link className='link' to="learn">Learn journey</Link>
            <Link className='link' to="doc">Documentation</Link>
            <Link className='link' to="design">Design</Link>
          </div>
          <div className='footer-links'>
            <Link className='link' to="tools">Tools</Link>
            <Link className='link' to="project">Projects</Link>
            <Link className='link' to="word">Word press</Link>
            <Link className='link' to="exam">Exam</Link>
          </div>
          <div className='footer-links'>
            <Link className='link' to="community">Community</Link>
            <Link className='link' to="ask">Ask</Link>
            <Link className='link' to="wohus">Who us</Link>
            <Link className='link' to="contactus">Contact us</Link>
          </div>
      </div>
<hr />
      <div className="footer-icon">
      <Link  target="_blank" to="/"><FontAwesomeIcon className='f-icon face' icon={faFacebookF} /></Link>
      <Link  target="_blank" to="/"><FontAwesomeIcon className='f-icon twet' icon={faTwitter} /></Link>
      <Link  target="_blank" to="/"><FontAwesomeIcon className='f-icon inst' icon={faInstagram} /></Link>
      <Link  target="_blank" to="/"><FontAwesomeIcon className='f-icon tele' icon={faTelegram} /></Link>
      <Link  target="_blank" to="/"><FontAwesomeIcon className='f-icon wapp' icon={faWhatsapp} /></Link>
      </div>
      <div className="copy-right">
        Copyright © 2023 <span> Learn From scratch </span> All rights reserved
      </div>
    </div>
  )
};

export default footer