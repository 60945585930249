import React from 'react'
import './ask.css';
import second from '../../../image/Question Mark.jpeg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faQuestion , faArrowRight} from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";

const ask = () => {
  return (
    <div className="ask">
      <div className="sec9-text cont-head-text">
        <h1 className='head-text'>Ask</h1>
        <h3>This section is specialized and aims to provide comprehensive and effective solutions to user questions and inquiries</h3>
      </div>
        <div className='sec9-image'>
          <img src={second} alt="" />
        </div>
    <div className="sec9-text-4">
    <FontAwesomeIcon className=' icon-r' icon={faArrowRight} />
      <h3>Connect with a community that shares similar interests<FontAwesomeIcon className=' icon' icon={faQuestion} /></h3>
    </div>
    <div className="sec9-text-2">
    <FontAwesomeIcon className=' icon-r' icon={faArrowRight} />
      <h3>Quick and creative solutions to web development questions<FontAwesomeIcon className=' icon' icon={faQuestion} /></h3>
    </div>
    <div className="sec9-text-1">
    <FontAwesomeIcon className=' icon-r' icon={faArrowRight} />
      <h3>Wide range of answers and solutions to common questions<FontAwesomeIcon className=' icon' icon={faQuestion} /></h3>
    </div>
    <div className="sec9-text-3">
    <FontAwesomeIcon className=' icon-r' icon={faArrowRight} />
      <h3>Reliable and effective solutions to their questions and inquiries<FontAwesomeIcon className=' icon' icon={faQuestion} /></h3>
    </div>
    <Link className="btn" to="ask">Ask a question <FontAwesomeIcon className=' icon-btn' icon={faArrowRight} /></Link>
    </div>
  )
}

export default ask