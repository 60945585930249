import React from 'react'
import './project.css';
import second from '../../../image/porject.png'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faArrowRightToFile ,faArrowRight} from '@fortawesome/free-solid-svg-icons'
const project = () => {
  return (
    <div className="project">
      <div className="sec6-text cont-head-text">
        <h1 className='head-text'>Projects</h1>
        <h3>This section presents a wide variety of projects covering various areas of web development</h3>
      </div>
      <div className="sec6-image-r">
      <img src={second} alt="" />
      </div>

        <div className="sec6-text1">
        <FontAwesomeIcon className=' icon' icon={faArrowRightToFile} />
        <h3>Providing new ideas and creative solutions</h3>
        </div>
        <div className="sec6-text2">
        <FontAwesomeIcon className=' icon' icon={faArrowRightToFile} />
          <h3>Design and development of static websites projects.</h3>
        </div>
        <div className="sec6-text3">
        <FontAwesomeIcon className=' icon' icon={faArrowRightToFile} />
          <h3> Advanced projects containing high-end design techniques</h3>
        </div>
        <div className="sec6-text4">
        <FontAwesomeIcon className=' icon' icon={faArrowRightToFile} />
          <h3>The department is characterized by providing project codes</h3>
        </div>
        <Link className="btn" to="project">View More Projects<FontAwesomeIcon className=' icon-btn' icon={faArrowRight} /></Link>
    </div>
  )
}

export default project