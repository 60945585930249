import React from 'react'
import './tools.css';
import { Link } from "react-router-dom";
import sec3_image1 from '../../../image/Visual_Studio_Code_1.35_icon.svg.png'
import sec3_image2 from '../../../image/sec3-image2.png'
import sec3_image3 from '../../../image/58482973cef1014c0b5e49fd.png'
import sec3_image4 from '../../../image/pngimg.com - github_PNG83.png'
import sec3_image5 from '../../../image/npm-logo.png'
import sec3_image6 from '../../../image/62cc1b51150d5de9a3dad5f8.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

const tools = () => {
  return (
    <div className="tools">
        <div className="sec3-text cont-head-text">
        <h1 className='head-text'>Tools</h1>
        <h3>In this section, you will find all the tools that help you in the field of <b>Webdevelopment</b></h3>
        </div>
        <div className="containrt">
          <div className="card">
            <img src={sec3_image1} alt="" />
            <div className="card-text">
              <h1>Visual Studio Code</h1>
              <p>Visual Studio Code is a development environment that combines ease of use with power, and is a favorite of many developers for developing applications and websites across various programming languages.</p>
            </div>
          </div>

          <div className="card">
            <img src={sec3_image2} alt="" />
            <div className="card-text">
              <h1>Visual Studio</h1>
              <p>Visual Studio is one of the most powerful development environments available, and is preferred by many developers because of its power, flexibility, and comprehensive support provided by Microsoft and the developer community around the world.</p>
            </div>
          </div>

          <div className="card">
            <img src={sec3_image4} alt="" />
            <div className="card-text">
              <h1>Git Hub</h1>
              <p>GitHub allows developers and teams to create, upload and manage repositories for their projects using the Git version control system. This means that it is easy to track changes and updates to the project over time</p>
            </div>
          </div>

          <div className="card">
            <img src={sec3_image5} alt="" />
            <div className="card-text">
              <h1>Npm</h1>
              <p>npm stands for "Node Package Manager" and is a tool used in the Node.js environment to manage the download, installation and management of software packages and software libraries.</p>
            </div>
          </div>

          <div className="card">
            <img src={sec3_image3} alt="" />
            <div className="card-text">
              <h1>Xampp</h1>
              <p>XAMPP is an open source development package that enables you to create and run a local web server on your computer. Thus, it is an integrated system that includes all the tools needed to develop and test web applications.</p>
            </div>
          </div>

          <div className="card">
            <img src={sec3_image6} alt="" />
            <div className="card-text">
              <h1>Postman</h1>
              <p>Postman is a desktop application and online service used to test, document, test, and troubleshoot APIs. Postman is commonly used by developers and software development teams to make it quick and easy to test and develop APIs.</p>
            </div>
          </div>

        </div>
        <Link className="btn" to="tools">View More Tools <FontAwesomeIcon className=' icon-btn' icon={faArrowRight} /></Link>
      </div>
  )
}

export default tools