import React from 'react'
import './doc.css';
import sec2_image from '../../../image/doc_image.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight , faFileCode , faMicrochip , faCodeBranch ,faLaptopCode } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";
const doc = () => {
  return (
    <div className="doc">
      <div className="doc-l">
        <div className="sec2-text-l cont-head-text">
        <h1 className='head-text'>Documentation</h1>
        <p>In this section have afull documentation in your track <b>Webdevelopment</b></p>
        </div>
      <div className="sec2-image">
        <img src={sec2_image} alt="" />
      </div>
      </div>
      <div className="doc-r">

        <div className="main-box">
          <div className="box">
          <FontAwesomeIcon className='icon-sec2-r' icon={faCodeBranch} />
          <div className="sec2-text-r">
            <h2> Full Referanc</h2>
              <p>We provide you with a variety of references and resources that will help you reach an outstanding level in website development</p>
          </div>
          </div>

          <div className="box">
          <FontAwesomeIcon className='icon-sec2-r' icon={faLaptopCode} />
          <div className="sec2-text-r">
            <h2>Tutorials</h2>
            <p>The contents of our site vary from tutorials on popular programming languages such as HTML, CSS, JavaScript and others.</p>
          </div>
          </div>

          <div className="box">
          <FontAwesomeIcon className='icon-sec2-r' icon={faMicrochip} />
          <div className="sec2-text-r">
            <h2>Latest Technology</h2>
            <p>We make sure to update our content regularly to keep you up to date with the latest technologies and practices in the world of web development.</p>
          </div>
          </div>


          <div className="box">
          <FontAwesomeIcon className='icon-sec2-r' icon={faFileCode} />
          <div className="sec2-text-r">
            <h2>Clear explanations</h2>
            <p>Looking to understand the basics of website development or a professional who wants to improve their skills, our website will be a reliable companion for you on your educational journey.</p>
          </div>
          </div>
        </div>

      </div>
      <div>
      <Link className="btn" to="doc">Documentation <FontAwesomeIcon className=' icon-btn' icon={faArrowRight} /></Link>
      </div>
    </div>
  )
}

export default doc