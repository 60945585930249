import React from 'react'

import './exam.css';
import second from '../../../image/test.jpeg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";
const exam = () => {
  return (
    <div className="exam">
        <div className="sec8-text cont-head-text">
        <h1 className='head-text'>Exercises</h1>
        <h3>This section aims to provide a variety of programming exercises and exams in the field of <b>Webdevelopment</b>.</h3>
      </div>
    <div className='sec8-image'>
      <img src={second} alt="" />
    </div>
    <div className="sec8-text-1">
      <h3>Web programming exercises and exams</h3>
    </div>
    <div className="sec8-text-2">
      <h3>Various exercises in popular web languages </h3>
    </div>
    <div className="sec8-text-3">
      <h3>Application challenges. </h3>
    </div>
    <div className="sec8-text-4">
      <h3>Web programming exercises and exams</h3>
    </div>
    <div className="sec8-text-5">
      <h3>Various exercises in popular web languages </h3>
    </div>
    <div className="sec8-text-6">
      <h3>Application challenges. </h3>
    </div>
    <Link className="btn" to="exam">Get Started <FontAwesomeIcon className=' icon-btn' icon={faArrowRight} /></Link>
    </div>
  )
}

export default exam