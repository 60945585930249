import React from 'react'
import './whous.css';
import sec11_image from '../../../image/logo2.gif'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestion} from '@fortawesome/free-solid-svg-icons'
// import { Link } from "react-router-dom";
const whous = () => {
  return (
    <div className="whous">
      <div className="sec11-text cont-head-text">
        <h1 className='head-text'>Who Us <FontAwesomeIcon className=' icon' icon={faQuestion} /></h1>
        <h3>In this section, you will find all the tools that help you in the field of <b>Webdevelopment</b></h3>
      </div>
      <div className="container">
        <div className="text-l">
          <h1>Learn From Scratch</h1>
          <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aliquam tempore necessitatibus corporis hic totam labore ad aut dicta cumque eum iure ipsum enim vitae eius repellendus, mollitia distinctio qui obcaecati.</p>
          <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aliquam tempore necessitatibus corporis hic totam labore ad aut dicta cumque eum iure ipsum enim vitae eius repellendus, mollitia distinctio qui obcaecati.</p>
          <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aliquam tempore necessitatibus corporis hic totam labore ad aut dicta cumque eum iure ipsum enim vitae eius repellendus, mollitia distinctio qui obcaecati.</p>
          <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aliquam tempore necessitatibus corporis hic totam labore ad aut dicta cumque eum iure ipsum enim vitae eius repellendus, mollitia distinctio qui obcaecati.</p>
          <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aliquam tempore necessitatibus corporis hic totam labore ad aut dicta cumque eum iure ipsum enim vitae eius repellendus, mollitia distinctio qui obcaecati.</p>
        </div>
        <div className="image-r">
          <img src={sec11_image} alt="" />
        </div>
      </div>
    </div>
  )
}

export default whous