import React from 'react'
import './startsection.css';
import StartImage from '../../../image/startsection.gif'
const startSction = () => {
  return (
    <div className="start">
      <div className="sec-start-l">
        <div className="blur-t"></div>
      <div className="sec-start-text-l">
        <h1 className='head-text'>Start Your Journey to Learn </h1>
        <h2> Website Development</h2>
        <p>All you need to become web developer</p>
        <p>We will take you on a journey of enriching knowledge and skills, where you will learn how to design and build modern websites that attract attention and meet your demand step by step, whether you are a beginner with no background in development, or a professional to develop their skills to a new level.</p>
      </div>
      </div>
    <div className="sec-start-r">
      <div className="background">
        <div className="over-1"></div>
        <div className="over-2"></div>
        <div className="over-3"></div>
      <div className='sec0-image'>
        <img src={StartImage} alt="" />
      </div>
      </div>
    </div>

    </div>
  )
}

export default startSction