import React from 'react';
// import { Link } from "react-router-dom";
import logo from '../../image/logo.png'
import './nav.css';
const nav = () => {
  return (
    <div className="navbar">
      <div className="logo">
        <a href='/'>  <img className='logo_img' src={logo} alt="Logo" /></a>
      </div>
      <ul className="menu-items">
        <li><a href="/">Home</a></li>
        <li><a href="/">journey Learn</a></li>
        <li><a href="/">Documentation</a></li>
        <li><a href="/">Design</a></li>
        <li><a href="/">Tools</a></li>
        <li><a href="/">Projects</a></li>
        <li><a href="/">Word press</a></li>
        <li><a href="/">Exam</a></li>
        <li><a href="/">Community</a></li>
        <li><a href="/">Ask</a></li>
        <li><a href="/">Who us</a></li>
        <li><a href="/">Contact us</a></li>
        <li><a href="/">Footer</a></li>
        {/* <Link to="/" >Home</Link>
        <Link to="learn">Learn journey</Link>
        <Link to="doc">Documentation</Link>
        <Link to="design">Design</Link>
        <Link to="tools">Tools</Link>
        <Link to="project">Projects</Link>
        <Link to="word">Word press</Link>
        <Link to="exam">Exam</Link>
        <Link to="community">Community</Link>
        <Link to="ask">Ask</Link>
        <Link to="wohus">Who us</Link>
        <Link to="contactus">Contact us</Link>
        <Link to="#">Footer</Link> */}
      </ul>
    </div>
  )
};

export default nav;
