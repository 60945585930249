import './App.css';
  // routs
      // import {Routes , Route} from "react-router-dom"
      import Home from './commponent/content/home/home'
      import Nav from './commponent/nav_bar/nav'
      import Footer from './commponent/footer/footer'
      // import Learn from './commponent/content/section_1/learn'
      // import Doc from './commponent/content/section_2/doc'
      // import Design from './commponent/content/section_3//design'
      // import Tools from './commponent/content/section_4/tools'
      // import Project from './commponent/content/section_5/project'
      // import Word from './commponent/content/section_6/word'
      // import Exam from './commponent/content/section_7/exam'
      // import Community from './commponent/content/section_8/community'
      // import Ask from './commponent/content/section_9/ask'
      // import Wohus from './commponent/content/section_10/whous'
      // import Contactus from './commponent/content/section_11/contactus'

function App() {
  
  return (
    <div className="App">
      <Nav/>
      <Home/>
      {/* <Routes>
      <Route path= "/"element={<Home/>}/>
      <Route path= "learn" element={<Learn/>} />
      <Route path= "doc" element={<Doc/>} />
      <Route path= "design" element={<Design/>} />
      <Route path= "tools" element={<Tools/>} />
      <Route path= "project" element={<Project/>} />
      <Route path= "word" element={<Word/>} />
      <Route path= "exam" element={<Exam/>} />
      <Route path= "community" element={<Community/>} />
      <Route path= "ask" element={<Ask/>} />
      <Route path= "wohus" element={<Wohus/>} />
      <Route path= "contactus" element={<Contactus/>} />
      </Routes> */}
      <Footer/>
    </div>
  );
} 

export default App;