import React from 'react'
import './contactus.css';
import sec12_image from '../../../image/logo3.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope ,faLocationDot ,faMessage ,faUserPen} from '@fortawesome/free-solid-svg-icons'
const contactus = () => {
  return (
    <div className="contactus">
      <div className="sec12-text cont-head-text">
        <h1 className='head-text'>Contact Us</h1>
        <h3>In this section, you will find all the tools that help you in the field of <b>Webdevelopment</b></h3>
      </div>
      <div className="container-form">
        <form action="">
          <label htmlFor="email"><FontAwesomeIcon className=' icon' icon={faEnvelope}  />Email</label>
          <input type="email" id="email" name="email" placeholder='Enter Your Email'  autoComplete="a"></input>

          <label htmlFor="name"><FontAwesomeIcon className=' icon' icon={faUserPen} />Name</label>
          <input type="name" id="name" name="name" placeholder='Enter Your Name' autoComplete="a"></input>

          <label htmlFor="address"><FontAwesomeIcon className=' icon' icon={faLocationDot} />Address</label>
          <input type="address" id="address" name="address" placeholder='Enter Your Address' autoComplete="a"></input>

          <label htmlFor="message"><FontAwesomeIcon className=' icon' icon={faMessage} />Message</label>
          <input type="message" id="message" name="message" placeholder='Enter Your Message' autoComplete="a"></input>
          <button className='form-btn'>Submit</button>
        </form>
      </div>
      <div className="sec12-image">
      <img src={sec12_image} alt="" />
      </div>
    </div>
  )
}

export default contactus