
import React from 'react'
import './learn.css';
import sec1_imge from '../../../image/sec1-image.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckDouble ,faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";



const learn = () => {
  return (
    <div className="learn">

    <div className="sec1-l">
    <div className="sec1-text-l cont-head-text">
        <h1 className='head-text'>Journey Learn</h1>
        <h3>The full track you need to become website developer.</h3>
    </div>
    </div>
    <div className="sec1-image">
      <img src={sec1_imge} alt="" />
    </div>
    <Link className='btn'  to="learn">Get Started <FontAwesomeIcon className='icon-btn' icon={faArrowRight} /></Link>
    <div className="sec1-r">
    <div className="sec1-text-r">
      <h1>What Will You Learn? </h1>
      <h3> <FontAwesomeIcon className='icon-check' icon={faCheckDouble} /> Front-End</h3>
      <h3> <FontAwesomeIcon className='icon-check' icon={faCheckDouble} /> Back-End</h3>
      <h3> <FontAwesomeIcon className='icon-check' icon={faCheckDouble} /> Full-Stack</h3>
    </div>
    </div>

    </div>
  )
}

export default learn