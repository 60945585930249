import React from 'react'
import './word.css';

import sec7_image from '../../../image/WordPress3.png'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWordpress} from '@fortawesome/free-brands-svg-icons'
import { faArrowRight} from '@fortawesome/free-solid-svg-icons'
const word = () => {
  return (
    <div className="word">
        <div className="sec7-text cont-head-text">
        <h1 className='head-text'>Word Press</h1>
        <h3>WordPress is the popular and powerful Content Management System (CMS) used to create and manage content.</h3>
        </div>
        <div className="sec7-image">
          <img src={sec7_image} alt="" />
        </div>
          <div className='text-container'>
              <div className="sec7-text1">
              <FontAwesomeIcon className=' icon' icon={faWordpress} />
                <h3>WordPress can be installed on different web servers easily.</h3>
                <p>It has a simple user interface that allows users to easily create and edit content without the need for advanced programming knowledge.
                Themes and Plugins: WordPress offers a wide range of ready-made themes and templates that enable users to easily customize the look of their site and suit their needs.</p>
              </div>
              <div className="sec7-text2">
                <FontAwesomeIcon className=' icon' icon={faWordpress} />
                <h3>It is a powerful and flexible tool for creating websites and blogs</h3>
                <p>WordPress is a powerful and flexible tool for creating and managing websites and blogs easily. It is the ideal choice for beginners and professionals alike, as it combines simplicity with extensibility and provides a professional content editing experience without the need for deep knowledge of web programming.</p>
              </div>
          </div>
        <Link className="btn" to="word">Learn Word press  <FontAwesomeIcon className=' icon-btn' icon={faArrowRight} /></Link>
    </div>
  )
}

export default word