import React from 'react'
import './home.css';
import Section0 from '../sction_0/startsction'
import Section1 from '../section_1/learn'
import Section2 from '../section_2/doc'
import Section3 from '../section_3/tools'
import Section4 from '../section_4/design'
import Section5 from '../section_5/tutorial'
import Section6 from '../section_6/project'
import Section7 from '../section_7/word'
import Section8 from '../section_8/exam'
import Section9 from '../section_9/ask'
import Section10 from '../section_10/community'
import Section11 from '../section_11/whous'
import Section12 from '../section_12/contactus'
const home = () => {
  return (
    <div className="home">
        <Section0/>
        <Section1/>
        <Section2/>
        <Section3/>
        <Section4/>
        <Section5/>
        <Section6/>
        <Section7/>
        <Section8/>
        <Section9/>
        <Section10/>
        <Section11/>
        <Section12/>
    </div>
  )
}

export default home